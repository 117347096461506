import PropTypes from 'prop-types';
import Image from '@/components/atoms/Image';
import Link from '@/components/atoms/Link';
import Text from '@/components/atoms/Text';

const CategoryBanner = ({
  name,
  image,
  slug,
  isDesktop,
  halfWidth,
  thirdWidth
}) => {
  const setWidth = (halfWidth && '50%') || (thirdWidth && '33%') || ' auto';

  return (
    <Link
      style={{ width: setWidth }}
      href={slug}
      center
    >
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Image
          alt={name}
          src={image?.url}
          width={isDesktop ? 720 : 500}
          height={isDesktop ? 400 : 277}
          priority
          cover
          imageStyles={{ width: '100%', height: '40vw', maxHeight: '360px' }}
        />
        <Text
          textCase="uppercase"
          variant="h2"
          textColor="white"
          weight="bold"
          textStyle={{
            textAlign: 'center',
            textShadow: '0 0 10px black',
            overflowWrap: 'break-word',
            fontSize: 'inherit',
            position: 'absolute',
            width: 'min-content'
          }}
        >
          {name}
        </Text>
      </div>
    </Link>
  );
};

CategoryBanner.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  halfWidth: PropTypes.bool,
  thirdWidth: PropTypes.bool
};

CategoryBanner.defaultProps = {
  halfWidth: false,
  thirdWidth: false
};

export default CategoryBanner;
