/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import useDeviceType from '@/hooks/useDeviceType';
import CategoryBanner from './CategoryBanner';

const CategoriesBanners = ({ categories }) => {
  const { isDesktop } = useDeviceType();

  if (!categories) return <></>;
  const categoriesLength = categories?.length;
  if (categoriesLength === 0) return <></>;

  const threeCategories = () => {
    if (!isDesktop) {
      return (
        <>
          <CategoryBanner
            name={categories[0].name}
            image={categories[0].image}
            slug={`/${categories[0].slug}`}
            isDesktop={!!isDesktop}
          />
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
              justifyContent: 'center'
            }}
          >
            {categories.slice(1).map((cat) => (
              <CategoryBanner
                key={cat.name}
                name={cat.name}
                image={cat.image}
                slug={`/${cat.slug}`}
                isDesktop={!!isDesktop}
                halfWidth
              />
            ))}
          </div>
        </>
      );
    } else {
      return (
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            gap: '1rem',
            marginTop: '1rem',
            justifyContent: 'center'
          }}
        >
          {categories.map((cat) => (
            <CategoryBanner
              key={cat.name}
              name={cat.name}
              image={cat.image}
              slug={`/${cat.slug}`}
              isDesktop={!!isDesktop}
              halfWidth
            />
          ))}
        </div>
      );
    }
  };

  const fourCategories = () => {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            gap: '1rem',
            marginTop: '1rem',
            justifyContent: 'center'
          }}
        >
          {categories.slice(0, 2).map((cat) => (
            <CategoryBanner
              key={cat.name}
              name={cat.name}
              image={cat.image}
              slug={`/${cat.slug}`}
              isDesktop={!!isDesktop}
              halfWidth
            />
          ))}
        </div>
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            gap: '1rem',
            marginTop: '1rem',
            justifyContent: 'center'
          }}
        >
          {categories.slice(2).map((cat) => (
            <CategoryBanner
              key={cat.name}
              name={cat.name}
              image={cat.image}
              slug={`/${cat.slug}`}
              isDesktop={!!isDesktop}
              halfWidth
            />
          ))}
        </div>
      </>
    );
  };

  const fiveCategories = () => {
    if (!isDesktop) {
      return (
        <>
          <CategoryBanner
            name={categories[0].name}
            image={categories[0].image}
            slug={`/${categories[0].slug}`}
            isDesktop={!!isDesktop}
          />
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
              justifyContent: 'center'
            }}
          >
            {categories.slice(1, 3).map((cat) => (
              <CategoryBanner
                key={cat.name}
                name={cat.name}
                image={cat.image}
                slug={`/${cat.slug}`}
                isDesktop={!!isDesktop}
                halfWidth
              />
            ))}
          </div>
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
              justifyContent: 'center'
            }}
          >
            {categories.slice(3).map((cat) => (
              <CategoryBanner
                key={cat.name}
                name={cat.name}
                image={cat.image}
                slug={`/${cat.slug}`}
                isDesktop={!!isDesktop}
                halfWidth
              />
            ))}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
              justifyContent: 'center'
            }}
          >
            {categories.slice(0, 2).map((cat) => (
              <CategoryBanner
                key={cat.name}
                name={cat.name}
                image={cat.image}
                slug={`/${cat.slug}`}
                isDesktop={!!isDesktop}
                halfWidth
              />
            ))}
          </div>
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
              justifyContent: 'center'
            }}
          >
            {categories.slice(2).map((cat) => (
              <CategoryBanner
                key={cat.name}
                name={cat.name}
                image={cat.image}
                slug={`/${cat.slug}`}
                isDesktop={!!isDesktop}
                thirdWidth
              />
            ))}
          </div>
        </>
      );
    }
  };

  switch (categoriesLength) {
    case 3:
      return threeCategories();
    case 4:
      return fourCategories();
    case 5:
      return fiveCategories();
    default:
      return <></>;
  }
};

CategoriesBanners.propTypes = {
  categories: PropTypes.array
};

CategoriesBanners.defaultProps = {
  categories: []
};
export default CategoriesBanners;
